var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centerx" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "modal-docs",
          attrs: { title: _vm.title, active: _vm.poModalActive },
          on: {
            "update:active": function ($event) {
              _vm.poModalActive = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("files-table", {
            attrs: {
              tableData: _vm.localData.filter((item) => item.null === false),
              variant: _vm.variant,
              showNullFiles: _vm.showNullFiles,
            },
            on: { markAsNull: _vm.markAsNull },
          }),
          _vm.showNullFiles
            ? _c(
                "div",
                [
                  _c("vs-divider", { staticClass: "my-4" }, [
                    _c(
                      "h4",
                      { staticClass: "text-lg text-bold text-black-light" },
                      [_vm._v("Anuladas")]
                    ),
                  ]),
                  _c("files-table", {
                    attrs: {
                      tableData: _vm.localData.filter(
                        (item) => item.null === true
                      ),
                      variant: _vm.variant,
                      showNullFiles: _vm.showNullFiles,
                    },
                    on: { markAsNull: _vm.markAsNull },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex justify-end mt-6 w-full" },
            [
              _c("input", {
                staticClass: "hidden",
                attrs: { type: "file", name: "addOdcInput", id: "addOdcInput" },
                on: { change: _vm.handleFileUpload },
              }),
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "border",
                    title: "Subir ODC",
                    icon: "backup",
                  },
                  on: { click: _vm.openFileInput },
                },
                [_vm._v("\n        Subir ODC\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }