var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { staticClass: "pt-4 min-h-full" }, [
    _c("h3", { staticClass: "mb-1" }, [
      _vm._v("\n      Ventas por países\n  "),
    ]),
    _c("span", [_vm._v("Objetivos Q" + _vm._s(_vm.currentQ))]),
    _c(
      "div",
      { staticClass: "mt-10" },
      [
        _vm.countries.length > 0
          ? _vm._l(_vm.countries, function (country, index) {
              return _vm.countries.length > 0
                ? _c("sales-country", {
                    key: index,
                    attrs: { country: country },
                  })
                : _vm._e()
            })
          : _c("div", { staticClass: "text-center text-gray-500" }, [
              _vm._v("No hay datos."),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }