<template>
    <vs-table noDataText="No data" :data="tableData">
        <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th>Fecha de subida ODC</vs-th>
            <vs-th>Facturado</vs-th>
            <vs-th>Archivo Factura</vs-th>
            <vs-th>Fecha subida Factura</vs-th>
            <vs-th v-if="variant === 'billing'">Cobrado</vs-th>
            <vs-th v-if="variant === 'billing'">Fecha de cobro</vs-th>
            <vs-th v-if="variant === 'payment'">Pagado</vs-th>
            <vs-th v-if="variant === 'payment'">Fecha de pago</vs-th>
            <vs-th>Acción</vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in tableData">
                <vs-td>
                    <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                        Ver
                    </a>
                </vs-td>
                <vs-td>
                    {{ tr.created_at ? dateFormatter(tr.created_at) : '-' }}
                </vs-td>
                <vs-td>
                    <vs-checkbox color="rgb(38, 187, 174)" v-model="tr.billed"
                        :title="tr.billed ? 'Facturado' : 'Marcar como facturado'" />
                </vs-td>
                <vs-td>
                    <template v-if="tr.billed_file">
                        <a style="padding-left: 10px;" :href="tr.billed_file" target="_blank">
                            Ver
                        </a>
                    </template>
                    <template v-else>
                        -
                    </template>
                </vs-td>
                <vs-td>
                    {{ tr.billed_at ? dateFormatter(tr.billed_at) : '-' }}
                </vs-td>
                <vs-td v-if="variant === 'billing'">
                    <vs-checkbox color="rgb(38, 187, 174)" v-model="tr.charged"
                        :title="tr.charged ? 'Cobrado' : 'Marcar como cobrado'" />
                </vs-td>
                <vs-td v-if="variant === 'billing'">
                    {{ tr.charged_at ? dateFormatter(tr.charged_at) : '-' }}
                </vs-td>
                <vs-td v-if="variant === 'payment'">
                    <vs-checkbox color="rgb(38, 187, 174)" v-model="tr.payed"
                        :title="tr.payed ? 'Pagado' : 'Marcar como pagado'" />
                </vs-td>
                <vs-td v-if="variant === 'payment'">
                    {{ tr.payed_at ? dateFormatter(tr.payed_at) : '-' }}
                </vs-td>
                <vs-td>
                    <div class="flex justify-start gap-2">
                        <vs-button v-if="showNullFiles" :color="tr.null ? 'dark' : 'danger'" type="border"
                            :title="tr.null ? 'Desanular' : 'Anular'" :icon="tr.null ? 'unarchive' : 'archive'"
                            @click="markAsNull(tr.id)" />
                        <vs-button color="primary" type="filled" title="Reemplazar" icon="repeat" />
                    </div>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>

<script>
import {dateFormatter} from '../../../utils/strings';

export default {
    name: 'files-table',
    components: {},
    data() {
        return {
            dateFormatter
        }
    },
    props: {
        tableData: {
            type: Array,
            default: null
        },
        variant: {
            type: String,
            default: 'billing'
        },
        showNullFiles: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        markAsNull(id) {
            this.$emit('markAsNull', id);
        }
    }
}
</script>