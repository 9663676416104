<template>
    <div class="centerx">
      <vs-popup 
        :title="title"
        :active.sync="poModalActive"
        @close="handleClose"
        class="modal-docs"
      >
        <!-- <h4 class="mb-4 text-lg text-bold text-black-light">Activos</h4> -->
        <files-table
          :tableData="localData.filter(item => item.null === false)"
          :variant="variant"
          :showNullFiles="showNullFiles"
          @markAsNull="markAsNull"
        />
        <div v-if="showNullFiles">
          <vs-divider class="my-4">
            <h4 class="text-lg text-bold text-black-light">Anuladas</h4>
          </vs-divider>
          <files-table
            :tableData="localData.filter(item => item.null === true)"
            :variant="variant"
            :showNullFiles="showNullFiles"
            @markAsNull="markAsNull"
          />
        </div>
        <div class="flex justify-end mt-6 w-full">
          <input 
            type="file" 
            name="addOdcInput" 
            id="addOdcInput" 
            class="hidden"
            @change="handleFileUpload"
          >
          <vs-button 
            color="primary" 
            type="border" 
            title="Subir ODC" 
            icon="backup" 
            @click="openFileInput"
          >
            Subir ODC
          </vs-button>
        </div>
      </vs-popup>
    </div>
</template>

<script>
import FilesTable from './FilesTable.vue';
export default {
    name: "show-docs-popup",
    components: {
        FilesTable
    },
    data () {
        return {
            localData: [],
        }
    },
    props: {
        poModalActive: {
            type: Boolean,
            default: false
        },
        poModalData: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        variant: {
          type: String,
          default: 'billing'
        },
        showNullFiles: {
          type: Boolean,
          default: true
        }
    },
    watch: {
        poModalData(newVal) {
            this.localData = newVal;
        },
        localData: {
          handler(newVal) {
            // Send data to api and update table data
            console.log(newVal);
          },
          deep: true
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:poModalActive', false); // Notify parent component
        },
        openFileInput() {
            document.getElementById('addOdcInput').click(); // Open file input
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            if (file.type !== 'application/pdf') {
                this.$vs.notify({
                    title: 'Error',
                    text: 'Solo se permiten archivos PDF',
                    color: 'danger'
                });
                event.target.value = ''; // Reset input
                return;
            }

            console.log(this.$refs)
            // Show loading notification
            this.$vs.loading({
                text: 'Subiendo archivo...',
            });

            // Simulate file upload promise
            new Promise((resolve, reject) => {
                const random = Math.random();
                setTimeout(() => {
                    if (random > 0.1) { // 90% success rate
                        resolve();
                    } else {
                        reject(new Error('Error al subir el archivo'));
                    }
                }, 1000);
            })
            .then(() => {
                this.$vs.loading.close();
                // Notify user and add new file to local data
                this.successFileUpload(file);
            })
            .catch(error => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Error',
                    text: error.message,
                    color: 'danger'
                });
                event.target.value = ''; // Reset input
                return;
            });
            return;
        },
        successFileUpload(file) {
          const todayDate = new Date()

          const fileName = file.name;
          // Notify user
          this.$vs.notify({
            title: 'Archivo subido correctamente',
            text: `Se ha subido el archivo ${fileName} correctamente :)`,
            color: 'success'
          });

          // Add new file to local data
          this.localData.push({
            id: this.localData.length + 1,
            file: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf",
            created_at: todayDate,
            billed_at: null,
            billed: false,
            charged_at: null,
            charged: false,
            null: false
          });
        },
        // Mark file as null
        markAsNull(id) {
            const item = this.localData.find(item => item.id === id);
            const isNull = item.null;

            this.localData.find(item => item.id === id).null = !isNull;
        }

    }
}


</script>