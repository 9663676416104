<template>
    <vue-apex-charts ref="apexChart" type="radialBar" height="100" width="100" :options="chartOptions" :series="[average]" />
</template>
<script>
import chartConfigs from './chartConfigs.js'
import VueApexCharts from 'vue-apexcharts'
import _color from '@/assets/utils/color.js'


export default {
  components: {
    VueApexCharts
  },
  props: {
    average: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chartOptions: null,
      series: []
    }
  },
  methods: {
    getHex (color) {
      if (_color.isColor(color)) {
        let rgb  = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${color}`)
        rgb = rgb.split(',')
        return `#${  ((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`
      }
      return color
    }
  },
  created () {
    try {
      this.chartOptions = Object.assign({}, chartConfigs.chartOptions)
    
      this.chartOptions['theme'] = {
        monochrome: {
          enabled: false,
          color: this.getHex(this.color),
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      }
    
      this.series = chartConfigs.series
    } catch (error) {
      console.log(error)
    }
  }
}
</script>