var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      attrs: { noDataText: "No data", data: _vm.tableData },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(_vm.tableData, function (tr, indextr) {
              return _c(
                "vs-tr",
                { key: indextr },
                [
                  _c("vs-td", [
                    _c(
                      "a",
                      {
                        staticStyle: { "padding-left": "10px" },
                        attrs: { href: tr.file, target: "_blank" },
                      },
                      [_vm._v("\n                    Ver\n                ")]
                    ),
                  ]),
                  _c("vs-td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          tr.created_at ? _vm.dateFormatter(tr.created_at) : "-"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _c(
                    "vs-td",
                    [
                      _c("vs-checkbox", {
                        attrs: {
                          color: "rgb(38, 187, 174)",
                          title: tr.billed
                            ? "Facturado"
                            : "Marcar como facturado",
                        },
                        model: {
                          value: tr.billed,
                          callback: function ($$v) {
                            _vm.$set(tr, "billed", $$v)
                          },
                          expression: "tr.billed",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      tr.billed_file
                        ? [
                            _c(
                              "a",
                              {
                                staticStyle: { "padding-left": "10px" },
                                attrs: {
                                  href: tr.billed_file,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Ver\n                    "
                                ),
                              ]
                            ),
                          ]
                        : [_vm._v("\n                    -\n                ")],
                    ],
                    2
                  ),
                  _c("vs-td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          tr.billed_at ? _vm.dateFormatter(tr.billed_at) : "-"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm.variant === "billing"
                    ? _c(
                        "vs-td",
                        [
                          _c("vs-checkbox", {
                            attrs: {
                              color: "rgb(38, 187, 174)",
                              title: tr.charged
                                ? "Cobrado"
                                : "Marcar como cobrado",
                            },
                            model: {
                              value: tr.charged,
                              callback: function ($$v) {
                                _vm.$set(tr, "charged", $$v)
                              },
                              expression: "tr.charged",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.variant === "billing"
                    ? _c("vs-td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              tr.charged_at
                                ? _vm.dateFormatter(tr.charged_at)
                                : "-"
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm.variant === "payment"
                    ? _c(
                        "vs-td",
                        [
                          _c("vs-checkbox", {
                            attrs: {
                              color: "rgb(38, 187, 174)",
                              title: tr.payed ? "Pagado" : "Marcar como pagado",
                            },
                            model: {
                              value: tr.payed,
                              callback: function ($$v) {
                                _vm.$set(tr, "payed", $$v)
                              },
                              expression: "tr.payed",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.variant === "payment"
                    ? _c("vs-td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              tr.payed_at ? _vm.dateFormatter(tr.payed_at) : "-"
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _c("vs-td", [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2" },
                      [
                        _vm.showNullFiles
                          ? _c("vs-button", {
                              attrs: {
                                color: tr.null ? "dark" : "danger",
                                type: "border",
                                title: tr.null ? "Desanular" : "Anular",
                                icon: tr.null ? "unarchive" : "archive",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.markAsNull(tr.id)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("vs-button", {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            title: "Reemplazar",
                            icon: "repeat",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", [_vm._v("Archivo")]),
          _c("vs-th", [_vm._v("Fecha de subida ODC")]),
          _c("vs-th", [_vm._v("Facturado")]),
          _c("vs-th", [_vm._v("Archivo Factura")]),
          _c("vs-th", [_vm._v("Fecha subida Factura")]),
          _vm.variant === "billing"
            ? _c("vs-th", [_vm._v("Cobrado")])
            : _vm._e(),
          _vm.variant === "billing"
            ? _c("vs-th", [_vm._v("Fecha de cobro")])
            : _vm._e(),
          _vm.variant === "payment"
            ? _c("vs-th", [_vm._v("Pagado")])
            : _vm._e(),
          _vm.variant === "payment"
            ? _c("vs-th", [_vm._v("Fecha de pago")])
            : _vm._e(),
          _c("vs-th", [_vm._v("Acción")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }