export default {
  chartOptions: {
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: '50px'
      }
    },
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    colors: ['#62f436', '#31500e'],
    stroke: {
      width: [0, 4],
      curve: 'smooth'
    },
    markers: {
      size: 5
    },
    // title: {

    //   text: 'Traffic Sources'
    // },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1]
    },
    labels: [
      '01/24',
      '02/24',
      '03/24',
      '04/24',
      '05/24',
      '06/24',
      '07/24',
      '08/24',
      '09/24',
      '10/24',
      '11/24',
      '12/24'
    ]
  }
}
