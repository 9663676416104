export const expectedActiveDisplays = [
  {q: 3, displays: 2800},
  {q: 4, displays: 3000}
]

export const getPercentDisplaysActive = ({displayCount, q}) => {
  const expectedDisplays = expectedActiveDisplays.find((display) => display.q === q)

  if (expectedDisplays) {
    const percent = (displayCount / expectedDisplays.displays) * 100
    
    return `${percent.toFixed(2)}%`
  }
}