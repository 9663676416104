var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full h-full flex flex-col justify-between" },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          {
            staticClass:
              "display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
            attrs: { id: "displays_active" },
          },
          [
            _c(
              "vx-tooltip",
              {
                attrs: { color: "dark", text: "Pantallas activas actualmente" },
              },
              [
                _c(
                  "statistics-card-line",
                  {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "MonitorIcon",
                      color: "primary",
                      statistic: _vm.stats.displays_active_count,
                      statisticTitle: "Pantallas activas",
                    },
                  },
                  [
                    _c("p", { staticClass: "text-3xl" }, [
                      _vm._v(
                        _vm._s(_vm.stats.percent_display_active) +
                          " Q" +
                          _vm._s(_vm.currentQ)
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
            attrs: { id: "displays_for_sales" },
          },
          [
            _c(
              "vx-tooltip",
              {
                attrs: {
                  color: "dark",
                  text: "Pantallas que están a la venta actualmente",
                },
              },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    "icon-right": "",
                    icon: "MonitorIcon",
                    color: "primary",
                    statistic: _vm.stats.displays_for_sale_count,
                    statisticTitle: "Pantallas a la venta",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
            attrs: { id: "displays_active_camlytics" },
          },
          [
            _c(
              "vx-tooltip",
              {
                attrs: {
                  color: "dark",
                  text: "Pantallas activas actualmente con camlytics",
                },
              },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    "icon-right": "",
                    icon: "VideoIcon",
                    color: "success",
                    statistic: _vm.stats.displays_active_camlytics_count,
                    statisticTitle: "Pantallas activas con cámara",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          {
            staticClass:
              "vx-col w-full mb-base min-h-full lg:w-2/3 xl:w-2/3 vs-con-loading__container",
          },
          [
            _c("year-goals-chart", {
              attrs: { series: _vm.series, average: _vm.average },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "vx-col w-full mb-base lg:w-1/3 min-h-full xl:w-1/3 vs-con-loading__container",
          },
          [
            _c("sales-country-panel", {
              attrs: {
                countries: _vm.stats.total_price_with_profit_usd_by_country,
                currentQ: _vm.currentQ,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", {}, [
        _c("span", { staticClass: "pl-4 mb-4 block" }, [
          _vm._v("Datos del día"),
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            {
              staticClass:
                "campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
              attrs: { id: "campaigns_active_count" },
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: "Campañas activas hoy" } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "BookIcon",
                      color: "primary",
                      statistic: _vm.stats.campaigns_active_count,
                      statisticTitle: "Campañas activas",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
              attrs: { id: "display_campaigns_active" },
            },
            [
              _c(
                "vx-tooltip",
                {
                  attrs: {
                    color: "dark",
                    text: "Pantallas con campañas activas hoy",
                  },
                },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "MonitorIcon",
                      color: "primary",
                      statistic: _vm.stats.display_campaigns_active,
                      statisticTitle: "Pantallas con campañas activas",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
              attrs: { id: "content_display_campaigns_active" },
            },
            [
              _c(
                "vx-tooltip",
                {
                  attrs: { color: "dark", text: "Creativos activos de de hoy" },
                },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "SunIcon",
                      color: "success",
                      statistic: _vm.stats.content_display_campaigns_active,
                      statisticTitle: "Creativos activos",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }