const countriesAmerica = [
  { name: 'Argentina', country_code: 'AR' },
  { name: 'Bolivia', country_code: 'BO' },
  { name: 'Brasil', country_code: 'BR' },
  { name: 'Canadá', country_code: 'CA' },
  { name: 'Chile', country_code: 'CL' },
  { name: 'Colombia', country_code: 'CO' },
  { name: 'Costa Rica', country_code: 'CR' },
  { name: 'Cuba', country_code: 'CU' },
  { name: 'República Dominicana', country_code: 'DO' },
  { name: 'Ecuador', country_code: 'EC' },
  { name: 'El Salvador', country_code: 'SV' },
  { name: 'Guayana Francesa', country_code: 'GF' },
  { name: 'Guatemala', country_code: 'GT' },
  { name: 'Guyana', country_code: 'GY' },
  { name: 'Honduras', country_code: 'HN' },
  { name: 'Haití', country_code: 'HT' },
  { name: 'Jamaica', country_code: 'JM' },
  { name: 'México', country_code: 'MX' },
  { name: 'Nicaragua', country_code: 'NI' },
  { name: 'Panamá', country_code: 'PA' },
  { name: 'Paraguay', country_code: 'PY' },
  { name: 'Perú', country_code: 'PE' },
  { name: 'Surinam', country_code: 'SR' },
  { name: 'Trinidad y Tobago', country_code: 'TT' },
  { name: 'Estados Unidos', country_code: 'US' },
  { name: 'Uruguay', country_code: 'UY' },
  { name: 'Venezuela', country_code: 'VE' }
]

export const salesData = [
  { country: 'Factura Internacional', Q3: 677000, Q4: 986000 },
  { country: 'Perú', Q3: 100000, Q4: 120000 },
  { country: 'Colombia', Q3: 60000, Q4: 70000 },
  { country: 'Guatemala', Q3: 10000, Q4: 8000 },
  { country: 'Costa Rica', Q3: 90000, Q4: 120000 },
  { country: 'Argentina', Q3: 15000, Q4: 20000 },
  { country: 'Chile', Q3: 20250, Q4: 27000 },
  { country: 'Mexico', Q3: 20000, Q4: 25000 }
]

export const getCountryCode = (name) => {
  const country = countriesAmerica.find((country) => country.name === name)

  if (country) {
    return country.country_code
  }

  return '-'
}

export const getQSalesPercentByCountry = ({country, q, sum}) => {
  const sales = salesData.find((sale) => sale.country === country)
  const cleanValue = sum.replace(/[^0-9.]/g, '')

  const numericValue = parseFloat(cleanValue.replace(/,/g, ''))

  if (sales) {
    const qSales = sales[`Q${q}`]
    const percent = (numericValue / qSales) * 100
    return `${percent.toFixed(2)}%`
  }

  return '-'
  
}
