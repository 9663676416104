export const campaignStatusMapping = () => ({
  pending: 'Pendiente',
  ready: 'Lista'
});

export const paymentStatusMapping = () => ({
  pending: 'Pendiente',
  approved: 'Aprobado',
  in_process: 'Procesando',
  in_mediation: 'En mediación',
  rejected: 'Rechazado',
  cancelled: 'Cancelado',
  refunded: 'Reintegrado',
  charge_back: 'Contracargo'
});

export const campaignInvoicesMapping = () => ({
  0: 'No facturado',
  1: 'Facturado'
});

export const campaignChargesMapping = () => ({
  0: 'No cobrado',
  1: 'Cobrado'
});

export const simplePaymentStatusMapping = () => {
  return {
    0: 'No pagado',
    1: 'Pagado'
  }
}