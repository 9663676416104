<template>
    <vx-card class="pt-4 min-h-full">
      <h3 class="mb-1">
          Ventas por países
      </h3>
      <span>Objetivos Q{{currentQ}}</span>
      <!-- Countries container -->
      <div class="mt-10">
          <!-- country -->
          <template
              v-if="countries.length > 0"
          >
              <sales-country
                  v-if="countries.length > 0"
                  v-for="(country, index) in countries"
                  :key="index"
                  :country="country"
              />
          </template>
          <!-- no countries -->
          <div v-else class="text-center text-gray-500">No hay datos.</div>
      </div>
    </vx-card>
</template>

<script>
import SalesCountry from '@/components/sales-country/SalesCountry.vue'

export default {
  components: {
    SalesCountry
  },
  props: {
    countries: {
      type: Array,
      required: true
    },
    currentQ:{
      type: Number,
      required: true
    }
  }
}

</script>