const monthlyOkr = [
  { monthNumber: 1, okr: 74625 },
  { monthNumber: 2, okr: 128250 },
  { monthNumber: 3, okr: 165625 },
  { monthNumber: 4, okr: 94625 },
  { monthNumber: 5, okr: 112625 },
  { monthNumber: 6, okr: 169625 },
  { monthNumber: 7, okr: 305933 },
  { monthNumber: 8, okr: 362733 },
  { monthNumber: 9, okr: 376233 },
  { monthNumber: 10, okr: 420333 },
  { monthNumber: 11, okr: 500333 },
  { monthNumber: 12, okr: 500333 }
]

export const getMonthOKR = (monthNumber) => {
  const foundOkr = monthlyOkr.find((month) => month.monthNumber === monthNumber)

  if (foundOkr) {
    return foundOkr.okr
  }
}

export const getToCurrentMonthOKR = () => {
  const indexCurrentMonth = new Date().getMonth() + 1

  return monthlyOkr
    .filter((okr) => okr.monthNumber <= indexCurrentMonth)
    .map((okr) => okr.okr)
}

export const getSumOKR = () => {
  return monthlyOkr.reduce((acc, curr) => acc + curr.okr, 0)
}
